import axios from 'axios'

class Ajax {
    //####################################################
    makeTwineEmbed(data, cbSuccess) {
        axios
            .post('/make', data)
            .then(cbSuccess)
            .catch((err) => console.log(err))
    }
    //####################################################
    getTwineEmbed(data, cbSuccess) {
        axios
            .post('/get', data)
            .then(cbSuccess)
            .catch((err) => console.log(err))
    }
    //####################################################
    addTwineReferer(data, cbSuccess) {
        axios
            .post('/addreferer', data)
            .then(cbSuccess)
            .catch((err) => console.log(err))
    }
}

const AjaxClient = new Ajax()
export default AjaxClient
