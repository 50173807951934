import React, { useState, useCallback, useContext } from "react"
import { makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useDropzone } from 'react-dropzone'
import DataStore from './stores/data'
import { observer } from 'mobx-react-lite'
import { CopyToClipboard } from 'react-copy-to-clipboard'


import {
    Typography,
    Button,
    Container,
    Paper,
    TextField,
} from '@material-ui/core'
import { green } from '@material-ui/core/colors'
import {

} from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '75vh',
        paddingTop: 64,
        paddingBottom: 64
    },
    inputContainer: {
        width: '100%',
        height: 100,
        border: '3px dashed #ccc',
        padding: theme.spacing(3)
    },
    dragActive: {
        borderColor: green[500]
    },
    input: {
        opacity: 0,
        width: '100%',
        height: '100%'
    },
    inputText: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%'
    },
    paper: {
        width: '100%'
    },
    spacer: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    logo: {
        transform: 'scaleY(-1)'
    }
}))


const TwineConvert = observer(() => {
    const classes = useStyles()
    const { importHTMLFile, shortId, embedCode } = useContext(DataStore)
    const onDrop = useCallback(importHTMLFile, [])
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })
   

    return (
        <Container maxWidth="sm" className={classes.root}>

            <img className={classes.logo} src="https://www.storiesinderschule.ch/uploads/4/4/1/2/44127859/published/twinebothands-question.png?1602691565" />

            <div className={classes.spacer} />

            <div {...getRootProps()} className={`${classes.inputContainer} ${(isDragActive ? classes.dragActive : '')}`} >
                <Typography variant="h6" className={classes.inputText}>Ziehe Deine Twine-Story (*.html) hier hin oder klick mich</Typography>
                <input {...getInputProps()} className={classes.input} />
            </div>

            <div className={classes.spacer} />

            {shortId && <>
                <Paper className={classes.paper} dangerouslySetInnerHTML={{ __html: embedCode }} />
                <CopyToClipboard text={embedCode}>
                    <Button fullWidth variant="contained" color="primary">Einbettcode kopieren</Button>
                </CopyToClipboard>
            </>
            }

        </Container>
    )
})

export default TwineConvert
