import React, { useEffect, useContext, Suspense } from 'react'
import ReactDOM from 'react-dom'
import { observer } from 'mobx-react-lite'

// Twine
import TwineConvert from './convert.js'
import TwineEmbed from './embed.js'
import * as serviceWorker from './serviceworker'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import CssBaseline from '@material-ui/core/CssBaseline'
//import '@fortawesome/fontawesome-pro/css/all.min.css'

import { makeStyles, useTheme, ThemeProvider } from '@material-ui/core/styles'


const useStyles = makeStyles(theme => ({
    '@global': {
        'html': {
            scrollBehavior: 'smooth'
        },
        'body': {
            background: 'red'
        },
        'html': {
            fontSize: 16,
            [theme.breakpoints.up("sm")]: {
                fontSize: 18
            },
            [theme.breakpoints.up("md")]: {
                fontSize: 20
            },
            [theme.breakpoints.up("lg")]: {
                fontSize: 24
            }
        }
    }
}))


const Main = observer((props) => {
    return (
     <> 
      <CssBaseline/>    
      <BrowserRouter>
       <Switch>
        <Route exact path="/" component={TwineConvert} />
        <Route path="/convert" component={TwineConvert} />
        <Route path="/embed" component={TwineEmbed} />
       </Switch>
      </BrowserRouter>
     </>
    )
})




ReactDOM.render(<Main />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
